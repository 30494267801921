/* eslint-disable */
import {lista, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro, Where} from "../../models/filtro/filtro";
import {BlogSCH} from "../../search/BlogSCH"
import {Blog} from '../../models/blog'
import {getTratamentoSCH} from "../../search/TratamentoSCH";

const url = '/blog'

const state = {
  all: [],
  allLista: [],
  filtro: new Filtro(),
  blog: new Blog(),
}

const getters = {
  listaAllBlogs: (state) => {
    return state.allLista !== undefined ? state.allLista : []
  },

  listaBlogs: (state) => {
    return state.all
  },

  pegaBlog: (state) => {
    return state.blog
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async setAllListBlog({ commit }) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
  },

  searchItem({commit}, search) {
    state.filtro.filters.addSearch(['titulo','resumo','texto'] ,
      "%"+search+"%",
      'ilike'
    )
  },
  async getItem({commit}, {id, onSucesso, onErro}) {
    try {
      var filtro = getTratamentoSCH('blog_id', id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      onSucesso(list.data[0])
    } catch (error) {
      onErro()
    }
  },


  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', BlogSCH(perPage))
  },
  setBlog({commit}, blog) {
     commit('SET_BLOG', blog)
  },
  async setAllBlogs({commit}, {onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_BLOGS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }

  },
  async setAllBlogsDesbloqueada({commit}) {
    const list = (await lista(url + '/desbloqueado')).data
    commit('SET_BlogS', list)
  },
  async processarBlog({commit}, {blog, acao,onSucesso,onErro}) {
    try {
      let res = await processar(url + (acao === 'gravar' ? '/commit' : ''), blog, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  }
}

const mutations = {
  SET_ALL_LIST (state, blogs) {
    state.allLista = blogs
  },
  SET_BLOGS(state, blogs) {
      state.all = blogs
  },
  SET_BLOG(state, blog) {
      state.blog = blog
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
