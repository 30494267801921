/* eslint-disable */
import {pegaDados, pegaLista} from '../../services/GenericAPI'
import {Filtro, Where} from "../../models/filtro/filtro";

import {BannerSCH, getBaseSCH} from "../../search/BannerSCH"
import {EventBus} from '../../helpers/event-bus'

const url = '/banner';

const state = {
  banner_array: [],
  filtro: new Filtro()
};

const getters = {
  pegaBannerArray: (state) => {
    return state.banner_array  !== undefined ? state.banner_array : []
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
};

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.addFilter('descricao' ,
      "%"+search+"%",
      'like'
    )
  },
  async getItem({commit}, id) {
    var filtro = getBaseSCH('banner_id', id, '=');
    const list = (await pegaLista(url, filtro)).data;
    commit('SET_BANNERS', list.data[0]);
    EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', BannerSCH(perPage))
  },

  async setAllBanners({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_BANNER_ARRAY', list.data)
      onSucesso()
    } catch (error) {
      onErro()
    }

  },
};

const mutations = {

  SET_BANNER_ARRAY(state, banner_array) {
    state.banner_array = banner_array
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
