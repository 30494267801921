/* eslint-disable */
import {pegaDados, pegaLista} from '../../services/GenericAPI'
import {Filtro, Where} from "../../models/filtro/filtro";

import {ProfissionalSCH} from "../../search/ProfissionalSCH"
import {EventBus} from '../../helpers/event-bus'

const url = '/profissional'

const state = {
  all: [],
  filtro: new Filtro(),
}

const getters = {
  listaProfissionals: (state) => {
    return state.all
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
   searchItem({commit}, search) {
    state.filtro.filters.addSearch(['titulo'] ,
      "%"+search+"%",
      'ilike'
    )
  },
  async getItem({commit}, id) {
     var filtro = getBaseSCH('profissional_id', id, '=')
     const list = (await pegaLista(url, filtro)).data
      commit('SET_ProfissionalS', list.data[0])
     EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', ProfissionalSCH(perPage))
  },
  async setAllProfissionals({commit}, {onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_PROFISSIONALS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }
  }
}

const mutations = {
  SET_PROFISSIONALS(state, profissionals) {
      state.all = profissionals
  },

  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
