import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import './assets/css/main.scss'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import 'lightbox2/dist/css/lightbox.css'
import 'aos/dist/aos.css'
import 'vue3-carousel/dist/carousel.css'

import {sync} from 'vuex-router-sync'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {createVuetify} from 'vuetify'
import Parallax from './components/Parallax'
import ModalCadastro from './components/ModalCadastro'
import Vue3DraggableResizable from 'vue3-draggable-resizable'
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'
import VueYtframe from "vue3-ytframe";
import lightbox from 'lightbox2'

import VueGoogleMaps from '@fawmi/vue-google-maps'
import TableIterator from "./components/TableIterator";
import SecaoParallax from "./components/SecaoParallax";


const vuetify = createVuetify({
  components,
  directives,
})


const app = createApp(App)
sync(store, router)
app.use(router)
app.use(vuetify)
app.use(store)
app.use(VueYtframe)
app.use(lightbox)
app.component('table-iterator', TableIterator)
app.component('parallax', Parallax)
app.component('secao-parallax', SecaoParallax)
app.component('pro-modal', ModalCadastro)
app.component('vue3-draggable-resizable', Vue3DraggableResizable)

// A key precisará ser alterada depois
app.use(VueGoogleMaps, {
  load: {
    v: '3.51',
    key: 'AIzaSyAb0wzUYfv-8mxhDsIS8nv7ddodU-Df5Eo',
    libraries: 'visualization'

  }
})

app.mount('#app')
