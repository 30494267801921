/* eslint-disable */
import {pegaDados} from '../../services/GenericAPI'
import {Filtro, Where} from "../../models/filtro/filtro";

import {DadosClinicaSCH} from "../../search/DadosClinicaSCH"
import {DadosClinica} from "../../models/dados_clinica";

const url = '/dados_clinica'

const state = {
  dados_clinica: new DadosClinica(),
  filtro: new Filtro(),
}

const getters = {
  pegaDadosClinica: (state) => {
    return state.dados_clinica
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', DadosClinicaSCH(perPage))
  },

  setDadosClinica({commit}, dados_clinica) {
    commit('SET_DADOS_CLINICA', dados_clinica)
  },

  async setAllDadosClinica({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_DADOS_CLINICA', list.data[0])
      } else {
        commit('SET_DADOS_CLINICA', new DadosClinica())
      }
      onSucesso()
    } catch (error) {
      onErro()
    }

  },
}

const mutations = {
  SET_DADOS_CLINICA(state, dados_clinica) {
      state.dados_clinica = dados_clinica
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
