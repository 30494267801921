/* eslint-disable */
import {pegaDados, processar} from '../../services/GenericAPI'
import {Filtro, Where} from "../../models/filtro/filtro";

import {ConvenioSCH} from "../../search/ConvenioSCH"
import {Convenio} from "../../models/convenio";

const url = '/convenios'

const state = {
  convenio: new Convenio(),
  filtro: new Filtro(),
}

const getters = {
  pegaConvenio: (state) => {
    return state.convenio
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', ConvenioSCH(perPage))
  },

  setConvenio({commit}, convenio) {
    commit('SET_CONVENIO', convenio)
  },

  async setAllConvenio({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_CONVENIO', list.data[0])
      } else {
        commit('SET_CONVENIO', new Convenio())
      }

      onSucesso()
    } catch (error) {
      onErro()
    }

  },

  async processarConvenio({commit}, {convenio, acao,onSucesso, onErro}) {
    try {
      let res = await processar(url+'/commit', convenio, acao)
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
      onErro()
    }
  }
}

const mutations = {
  SET_CONVENIO(state, convenio) {
      state.convenio = convenio
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
