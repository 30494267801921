<template>
	 <div class="modal_teste">
			<v-dialog v-model="Mostrar" :width="mobileModal(width)" :fullscreen="fullscreen" :hide-overlay="overlay"
								:contained="false" scroll-strategy="none">
				 <v-card>
						<v-card-title class="headline" primary-title id="modalhead"
													style="background: #00329a; color: white; border-top: 5px solid #e63e3f">
							 {{getTitulo()}}
						</v-card-title>
						<v-card-text>
							 <slot></slot>
						</v-card-text>
				 </v-card>
			</v-dialog>
	 </div>
</template>

<script async>
  export default {
    props: {
      titulo: {
        default: ''
      },
      modal: {
        default: false
      },
      fullscreen: {
        default: false
      },
      dragable: {
        default: false
      },
      width: {
        default: '50%'
      }
    },
    name: 'ModalCadastro',

    data() {
      return {
        dialog: false,
        overlay: false,
        margem_left: '25',
        classe: '',
      }
    },
    methods: {
      mobileModal(size) {
        var width = window.screen.width
        if (width <= 600) {
          size = '90%'
        } else if (width >= 601 && width <= 900) {
          size = '80%'
        } else if (width >= 901 && width <= 1300) {
          size = '70%'
        }
        return size
      },
      validarCampo(campo) {
        if (campo !== undefined && campo !== null && campo !== '') {
          return true
        } else {
          return false
        }
      },
      getTitulo() {
        if (this.validarCampo(this.titulo)) {
          return this.titulo
        } else {
          return this.$router.currentRoute.meta
        }
      },
    },
    computed: {
      Mostrar: {
        get() {
          return this.modal
        },
        set() {
          return this.Mostrar
        }
      },
      largura: {
        get() {
          return this.width
        },
        set() {
          return this.largura
        }
      },
    },
    watch: {
      modal: function() {
        if (this.modal) {
          var _this = this
          var inter = setInterval(function() {
            var mywidth = document.getElementById('drg')
            if (mywidth !== null) {
              var width = window.screen.width
              var w = mywidth.clientWidth
              var res = width - w
              res = res / 2
              res = (res * 100) / width
              _this.margem_left = parseInt(res)
            }
            clearInterval(inter)
          }, 500)
        }
      }
    }
  }
</script>
