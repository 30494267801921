/* eslint-disable */
import {createStore} from 'vuex'
import geral from './modules/geral'

import blog from './modules/blog'
import convenio from './modules/convenios'
import banner from './modules/banner2'
import contato from './modules/contatos'
import local_atendimento from './modules/local_atendimento'
import profissional from "./modules/profissional";
import dados_clinica from "./modules/dados_clinica";
import tratamento from "./modules/tratamento";

export default createStore({
  modules: {
    blog,
    geral,
    banner,
    tratamento,
    profissional,
    contato,
    convenio,
    dados_clinica,
    local_atendimento,
    // banner_control_modal
  }
})
