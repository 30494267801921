
const state = {
  tab: '10' | sessionStorage.getItem('tab'),
  search: '',
  dialogError: false,
  objMsgError: null,
  snackSuccess: false,
  msgSuccess: '',
  exibirLista: true,
};

const getters = {
  exibirLista: (state) => {
  return state.exibirLista
},
  getSearch: state => {
    return state.search
  },
  getTab: (state) => {
    return state.tab
  },
};

const actions = {
  setTab({ commit }, tab) {
    sessionStorage.setItem('tab',JSON.stringify(tab))
    commit('SET_TAB', tab);
  },

  setSearch ({ commit }, valor) {
    commit('SET_SEARCH', valor)
  },
  toggleDialogError ({ commit }) {
    commit('SET_DIALOGERROR', !state.dialogError)
  },
  setObjMsgError ({ commit }, valor) {
    commit('SET_OBJMSGERROR', valor);
    commit('SET_DIALOGERROR', true)
  },
  setExibirLista ({ commit }, valor) {
    commit('SET_EXIBIRlISTA', valor)
  },
  toggleSnackSuccess ({ commit }) {
    commit('SET_SNACKSUCCESS', !state.snackSuccess)
  },
  setMsgSuccess ({ commit }, valor) {
    commit('SET_MSGSUCCESS', valor);
    commit('SET_SNACKSUCCESS', true)
  },
};

const mutations = {
  SET_TAB (state, payload) {

    state.tab = payload
  },
  SET_EXIBIRlISTA(state, payload) {
    state.exibirLista = payload
  },
  SET_DIALOGERROR (state, payload) {
    state.dialogError = payload
  },
  SET_OBJMSGERROR (state, payload) {
    state.objMsgError = payload
  },
  SET_SNACKSUCCESS (state, payload) {
    state.snackSuccess = payload
  },
  SET_MSGSUCCESS (state, payload) {
    state.msgSuccess = payload
  },
  SET_SEARCH (state, payload) {
    state.search = payload
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
