<template>
	 <div>
			<button class="open-button" @click="IrParaUrl('https://api.whatsapp.com/send?phone=55' + dados_clinica.whatsapp)">
				 <img src="../assets/img/whats.svg" alt="">
			</button>
	 </div>
</template>

<script async>
  import {mapGetters} from 'vuex'

  export default {
    name: 'PopupWhats',
    data: () => ({
      contato: [],
    }),
    methods: {
      IrParaUrl(path, target = '_blank') {
        var a = document.createElement('a')
        a.href = path
        a.target = target
        a.click()
      },
    },
    computed: {
       ...mapGetters('dados_clinica', {
          dados_clinica: 'pegaDadosClinica'
       }),
    }
  }
</script>

<style scoped>
	 * {
			box-sizing: border-box;
	 }

	 .open-button {
			background-color: transparent;
			color: white;
			padding: 20px 20px;
			border: none;
			cursor: pointer;
			opacity: 0.8;
			position: fixed;
			bottom: 50px;
			right: 50px;
			width: 50px;
			height: 50px;
			z-index: 900;
	 }

	 .open-button img {
			cursor: pointer;
			position: fixed;
			bottom: 50px;
			right: 50px;
			width: 50px;
			height: 50px;
	 }
</style>
