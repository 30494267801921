<template>
	 <v-footer   absolute style="padding: 0px">
			<v-card theme="dark" class="flex" flat tile style="background: transparent; border-radius: 0px; width: 100%">
				 <!--<v-card-text class="gradiente_sistem">-->
						<!--<h3 >Etiam vitae efficitur orci. </h3>-->
				 <!--</v-card-text>-->
				 <v-card-text class=" fundoFooter">
						<v-container>
							 <v-row justify="center">
									<v-col cols="12" sm="12" md="4" lg="4">
												<v-img :src="logo" contain height="100"></v-img>
												<!--<v-col cols="12">-->
															<!--<h2 class="txt_branco" style="font-weight: bold">Redes Sociais</h2>-->
															<!--<br>-->
															<!--<v-item-group selected-class="bg-primary">-->
																		<!--<v-row justify="center">-->
																					<!--<v-col v-for="(img,index) in icones.getIcones()"-->
																												<!--cols="3" sm="3" md="3" lg="2">-->
																								<!--<v-item v-slot="{ isSelected, selectedClass }">-->
																											<!--<v-card elevation="0" style="background: transparent" height="30"-->
																																			<!--@click="IrParaUrl(img.link)">-->
																														<!--<v-img :src="img.src" style="height: 30px; width: 100%" contain></v-img>-->
																											<!--</v-card>-->
																								<!--</v-item>-->
																					<!--</v-col>-->
																		<!--</v-row>-->
															<!--</v-item-group>-->
												<!--</v-col>-->

										 <!--<h2 class="txt_branco" style="font-weight: bold">Nossa Missão</h2>-->
										 <!--<br>-->
										 <!--<p style="text-align: justify" class="txt_branco">-->
														<!--Etiam vitae efficitur orci. Vestibulum mollis sollicitudin ullamcorper.-->
										 <!--</p>-->
										 <!--<div style="text-align: left">-->
												<!--<v-btn variant="text" to="/sobre" @click="scrollToSection('empresa_pg')" block-->
															 <!--style="background: rgba(255,255,255,0.04)">Saiba +-->
												<!--</v-btn>-->
										 <!--</div>-->
									</v-col>
									<v-col cols="12" sm="4" md="4" lg="4" style="text-align: center">
										 <div style="text-align: center ">
												<h2 class="txt_branco" style="font-weight: bold">Localização</h2>
												<br>
												<v-row justify="center">
													 <v-col cols="12" sm="10" md="6" lg="7">
															<div >
																		<p style="color: white">
																					{{dados_clinica.nome_clinica}} - {{dados_clinica.logrado}}, {{dados_clinica.numero}}-{{dados_clinica.bairro}} -
																					{{dados_clinica.cidade}} - {{dados_clinica.uf}}</p>
															</div>
													 </v-col>
												</v-row>
										 </div>
									</v-col>
									<v-col cols="12" sm="4" md="4" lg="3">
										 <!--SITE SEGURO-->
										 <site-seguro classe="txt_branco" url="https://vidavascularjf.com.br"></site-seguro>
									</v-col>
							 </v-row>
						</v-container>
				 </v-card-text>
				 <v-card-text class="py-2 white--text text-center gradiente_vermelho">
						<span>Copyrigth &ensp; &copy;{{ new Date().getFullYear() }} —  <a href="http://www.prodatanet.com.br/"
																																							style="color: white; text-decoration: none"><b>Prodata Informática</b></a>. Todos os direitos reservados.</span>
				 </v-card-text>
			</v-card>
	 </v-footer>
</template>

<script>
  import icones from '../assets/js/img_icones'
  import miscMixin from '../helpers/misc'
  import SiteSeguro from './SiteSeguro/SiteSeguro'
  import {mapGetters} from "vuex";
  import logo from '../assets/img/logo.png'

  export default {
    name: 'Rodape',
    mixins: [miscMixin],
    data: () => ({
       logo,
      icones
    }),
    components: {
      SiteSeguro
    },
     computed: {
        ...mapGetters('dados_clinica', {
           dados_clinica: 'pegaDadosClinica'
        }),
     },
  }
</script>
