import {createRouter, createWebHistory} from 'vue-router'

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   // base: process.env.BASE_URL,
   routes: [
      {
         path: '/',
         name: '',
         component: () => import(/* webpackChunkName: "Home" */ './Dash.vue'),
         children: [
            {
               path: '/',
               name: 'home',
               component: () => import(/* webpackChunkName: "Home" */ './views/home/Home.vue')
            },

            {
               path: '/blog',
               name: 'Blog',
               component: () => import(/* webpackChunkName: "Blog" */ './views/blog/Blog.vue')
            },
            {
               path: '/blog/:blog_id',
               name: 'BlogArtigo',
               component: () => import(/* webpackChunkName: "BlogArtigo" */ './views/blog/Blog.vue')
            },
            {
               path: '/profissionais',
               name: 'Profissionais',
               component: () => import(/* webpackChunkName: "Profissionais" */ './views/profissionais/Profissional.vue')
            },
            {
               path: '/tratamento',
               name: 'Tratamento',
               component: () => import(/* webpackChunkName: "Tratamento" */ './views/tratamento/Tratamento.vue')
            },
            {
               path: '/tratamento/:tratamento_id',
               name: 'TratamentoArtigo',
               component: () => import(/* webpackChunkName: "Tratamento" */ './views/tratamento/Tratamento.vue')
            },
            {
               path: '/convenio',
               name: 'Convenio',
               component: () => import(/* webpackChunkName: "Convenio" */ './views/convenio/Convenio.vue')
            },
            {
               path: '/contato',
               name: 'Contato',
               component: () => import(/* webpackChunkName: "Contato" */ './views/contato/Contato.vue')
            },
            {
               path: '/local_atendimento',
               name: 'LocalAtendimento',
               component: () => import(/* webpackChunkName: "LocalAtendimento" */ './views/LocalAtendimento/LocalAtendimento.vue')
            },
            {
               path: '/doppler',
               name: 'Doppler',
               component: () => import(/* webpackChunkName: "Doppler" */ './views/doppler/DopperVasculares.vue')
            },

            {
               path: '/:pathMatch(.*)*',
               name: 'not-found',
               component: () => import(/* webpackChunkName: "404" */ './views/NotFound/NotFound.vue')
            },
         ]
      },
   ]
})

export default router
