import router from '../router'

export default {
  data: () => ({
    show_list: false,
    listaItens: [],
    info_alerta: '',
    cor_alerta: 'primary',
    show_alerta: false,
    precisao: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: false,
      allowBlank: false,
      min: Number.MIN_SAFE_INTEGER,
      max: Number.MAX_SAFE_INTEGER
    },
  }),
  methods: {
    cloneObj(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    itens(item) {
      this.listaItens = item
    },

    removerMasK(str) {
      return this.validarCampo(str) ? str.replace(/[^0-9a-zA-Z]+/g, '') : ''
    },
    cortarString(str, tam) {
      if (this.validarCampo(str)) {
        if (str.length > tam) {
          str = str.slice(0, tam) + '...'
        }
      }
      return str
    },
    validarCampo(campo) {
      if (campo !== undefined && campo !== null && campo !== '') {
        return true
      } else {
        return false
      }
    },
    retira_acentos(str) {
      var com_acento = 'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ!'
      var sem_acento = 'AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr '
      var novastr = ''
      for (var i = 0; i < str.length; i++) {
        var troca = false
        for (var a = 0; a < com_acento.length; a++) {
          if (str.substr(i, 1) === com_acento.substr(a, 1)) {
            novastr += sem_acento.substr(a, 1)
            troca = true
            break
          }
        }
        if (troca === false) {
          novastr += str.substr(i, 1)
        }
      }
      return novastr
    },

    redimensionando(id) {
      var teste_div = document.getElementById(id)
      var eleStyle = window.getComputedStyle(teste_div)
      var height = 760
      var hg = parseInt(eleStyle.width.replace('px', '')) - 430
      if (parseInt(eleStyle.width.replace('px', '')) <= 1100) {
        height = hg > 300 ? hg : 300
      } else {
        height = hg > 300 ? (hg > 760 ? 760 : hg) : 300
      }
      return height
    },
    IrParaUrl(path, target = '_blank') {
      var a = document.createElement('a')
      a.href = path
      a.target = target
      a.click()
    },
    scrollToSection(id, block = 'start') {
      var elmnt = document.getElementById(id)
      if (this.validarCampo(elmnt)) {
        elmnt.scrollIntoView({
          behavior: 'smooth',
          block: block
        })
      }
    },
    maskCep(cpf) {
      // eslint-disable-next-line no-useless-escape,no-useless-escape, no-useless-escape, no-useless-escape
      return cpf.replace(/(\d{2})(\d{3})(\d{3})/g, '\$1.\$2\-\$3')
    },
    maskTel(cpf) {
      // eslint-disable-next-line no-useless-escape,no-useless-escape, no-useless-escape, no-useless-escape
      if (this.validarCampo(cpf)) {
        return cpf.replace(/(\d{2})(\d{4,5})(\d{4})/g, '(\$1)\$2\-\$3')
      }
    },
    irPara(path, block = 'start') {
      var url_atual = this.retira_acentos(decodeURIComponent(window.location.pathname))
      var cortar = url_atual.replace('/', '')
      if (this.validarCampo(cortar)) {
        router.push({path: path}).catch(err => {
        })
        var _this = this
        var inter = setInterval(function() {
          _this.scrollToSection(path, block)
          clearInterval(inter)
        }, 100)
      } else {
        router.push({path: path}).catch(err => {
        })
        this.scrollToSection(path, block)
      }
    },
    irParaRota(path) {
      this.scrollToSection('app')
      router.push({path: path}).catch(err => {
      })
    },

    inverterData(newData) {
      var xvals = '';
      if (this.validarCampo(newData)) {
        xvals = newData.split('-');
        return xvals[2] + '/' + xvals[1] + '/' + xvals[0]
      } else {
        return xvals
      }
    },

    extrairData2(data,index=0) {
      let dt = ''
      if (this.validarCampo(data)) {
        let d = data.split('-');
        dt = d[index]
      }
      return dt
    },

    dataEstenso(dt){
      if (this.validarCampo(dt)) {
        var dtt = this.extrairData2(dt,0) + '-' + parseInt(this.extrairData2(dt,1)) + '-' + parseInt(this.extrairData2(dt,2))
        var data = new Date(dtt);
        var d = data.getDate();
        var m = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"][data.getMonth()];
        var y = data.getFullYear();
        return d + ' de ' + m + ' de ' + y
      } else {
        return ''
      }
    },
    scrollTop() {
      var elmnt = document.getElementById('inspire')
      elmnt.scrollIntoView({behavior: 'smooth'})
    },
    scrollToId(id) {
      var elmnt = document.getElementById(id)
      if (this.validarCampo(elmnt)) {
        elmnt.scrollIntoView({behavior: 'smooth'})
      }
    },
    AbriEmNovaAba(path){
      var a = document.createElement('a')
      a.href = path
      a.target = '_blank'
      a.click()
    },
    recarregar(path){
      var a = document.createElement('a')
      a.href = path
      a.click()
    },
    chunkArray(myArray, n, size=0) {
      let tempArray = []
      let chunk_size = size > 0 ? size : Math.ceil(Math.max(myArray.length / n, 1))
      for (let index = 0; index < myArray.length; index += chunk_size) {
        tempArray.push(myArray.slice(index, index + chunk_size))
      }
      return tempArray
    },
    logs(obj){
      if (this.validarCampo(obj)) {
        return JSON.parse(JSON.stringify(obj))
      } else {
        return obj
      }
    },
    toDecimal(v, tam = 8, valida_nan = false) {
      try {
        if (valida_nan) {
          if (isNaN(parseFloat(v))) {
            return 0
          } else {
            if (this.validarCampo(v)) {
              return parseFloat(parseFloat(v).toFixed(tam))
            } else {
              return 0
            }
          }
        } else {
          let ret = 0;
          if (this.validarCampo(v)) {
            if (!this.validarCampo(tam)) tam = 2
            ret = parseFloat(parseFloat(v).toFixed(tam))
          }
          return ret
        }
      } catch (e) {
        console.log('ERROR: ', e)
      }
    },
  },
}
