<template>
	 <v-app id="app">
			<v-main>
				 <router-view/>
			</v-main>
			<rodape></rodape>
			<popup-whats></popup-whats>
	 </v-app>
</template>
<script>
  import AOS from 'aos'
  import PopupWhats from './components/PopupWhats'
  import Rodape from './components/Rodape'
  export default {
    name: 'App',
    components: {
      PopupWhats,
      Rodape
    },
    created() {
      AOS.init()
    }
  }
</script>
