<template>
			<v-parallax :src="img" height="130" :scale="scale">
						<div class="d-flex flex-column fill-height justify-center align-left text-white">
									<div class="d-flex flex-column fill-height justify-center align-left text-white">
												<v-container >
															<v-row >
																		<v-col style="padding:2px" cols="12" sm="6" md="8" lg="8">
																					<h3 v-if="validarCampo(txt1)" data-aos="fade-down" class="subheading" style="text-transform: uppercase; font-weight: 100; color: #0e312c;">
																								{{txt1}}
																					</h3>
																					<h1 v-if="validarCampo(txt2)" :data-aos="validarCampo(txt1) ? 'fade-up' : 'fade-down'" class="text-h4 font-weight-thin mb-4">
																								{{txt2}}
																					</h1>
																		</v-col>
																		<v-col cols="12" sm="6" md="4" lg="4" v-if="showSearch" style="padding: 1px">
																					<v-text-field label="Pesquisar" variant="underlined" density="compact" hide-details  single-line
																																			append-inner-icon="mdi-magnify"  v-model="model" :search-input.sync="pesquisa"
																																			@keyup="Pesquisar"  @click:append-inner="onClick"></v-text-field>
																		</v-col>
															</v-row>

												</v-container>
									</div>
						</div>
			</v-parallax>
</template>

<script>
   /* eslint-disable */
   import {mapActions, mapGetters} from "vuex";

   export default {
      name: "SecaoParallax",
						props:['txt1','txt2','img','scale','showSearch'],
      components: {},
      data: () => ({
         model: null,
         pesquisa: null,
						}),
      methods: {
         ...mapActions('geral', [
            'setSearch']),
         validarCampo(campo) {
            if (campo !== undefined && campo !== null && campo !== '') {
               return true
            } else {
               return false
            }
         },
         onClick(){
            this.setSearch(this.model)
									},
         Pesquisar(event) {
            if (event.keyCode === 13) { // ENTER
               this.setSearch(this.model)
            }
         },
						},
      created() {
         this.model = this.validarCampo(this.search) ? this.search : ''
         this.setSearch(this.model)
      },
      mounted() {
      },
      computed: {
         ...mapGetters('geral', {search: 'getSearch'}),

						},
      watch: {
         'model': function () {
            if (!this.validarCampo(this.model)) {
               this.setSearch("")
            }
         },
         pesquisa: function (val) {
            if (this.validarCampo(val)) {
               this.setSearch(val)
            }
         },
						}
   }
</script>

<style scoped>
			h1,h3 {
						margin-block-start: 0.1em!important;
						margin-block-end: 0.1em!important;
			}
			@media screen and (min-width: 100px) and (max-width: 400px) {
						h3{font-size: 16px!important;}
						h1{font-size: 20px!important;}
			}
			@media screen and (min-width: 401px) and (max-width: 800px) {
						h3{font-size: 18px!important;}
						h1{font-size: 25px!important;}
			}

			@media screen and (min-width: 801px) and (max-width: 1000px) {
						h1{font-size: 30px!important;}
			}


</style>
