/* eslint-disable */
import {lista, pegaDados} from '../../services/GenericAPI'
import {Filtro, Where} from "../../models/filtro/filtro";

import {getTratamentoSCH, TratamentoSCH} from "../../search/TratamentoSCH"
import {EventBus} from '../../helpers/event-bus'
import {Tratamento} from '../../models/tratamento'

const url = '/tratamento'

const state = {
  all: [],
  allLista: [],
  filtro: new Filtro(),
  tratamento: new Tratamento(),
}

const getters = {
  listaTratamentos: (state) => {
    return state.all
  },

  pegaTratamento: (state) => {
    return state.tratamento
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async setAllListTratamento({ commit }) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
    EventBus.$emit('TRATAMENTOS_ALL_CONCLUIDO', true)
  },

  searchItem({commit}, search) {
    // state.filtro.filters.addSearch(['titulo'] ,
    state.filtro.filters.addSearch(['titulo', 'descricao','tratamento'] ,
      "%"+search+"%",
      'ilike'
    )
  },
  async getItem({commit}, {id, onSucesso, onErro}) {
    try {
      var filtro = getTratamentoSCH('tratamento_id', id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      onSucesso(list.data[0])
    } catch (error) {
      onErro()
    }
  },

  setFiltro({commit}, perPage) {
     commit('SET_FILTRO', TratamentoSCH(perPage))
  },
  setTratamento({commit}, tratamento) {
     commit('SET_TRATAMENTO', tratamento)
  },
  // Pag
  async filtarTratamento({commit}, filtro) {
    const list2 = (await filtrando(url, filtro)).data
    commit('SET_TRATAMENTOS', list2)
  },

  async setAllTratamentos({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data
      commit('SET_TRATAMENTOS', list)
      onSucesso()
    } catch (error) {
      onErro()
    }

  },
  async setAllTratamentosDesbloqueada({commit}) {
    const list = (await lista(url + '/desbloqueado')).data
    commit('SET_TRATAMENTOS', list)
  },
}

const mutations = {
  SET_ALL_LIST (state, subgrupos) {
    state.allLista = subgrupos
  },
  SET_TRATAMENTOS(state, tratamentos) {
      state.all = tratamentos
  },
  SET_TRATAMENTO(state, tratamento) {
      state.tratamento = tratamento
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
