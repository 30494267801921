
import img_1 from '../img/icones/ico (1).png'
import img_2 from '../img/icones/ico (2).png'
import img_3 from '../img/icones/ico (3).png'


const img = [

  {ico_id: 1, src: img_1, link: '',nome:'youtube'},
  {ico_id: 2, src: img_2, link: '',nome:'facebook'},
  {ico_id: 3, src: img_3, link: '',nome:'instagram'},
]
export default {
  getIcones() {
    return img
  },
}
