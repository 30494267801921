/* eslint-disable */
import {pegaDados, processar} from '../../services/GenericAPI'
import {EventBus} from '../../helpers/event-bus'

const url = 'contato'

const state = {
}


const getters = {
}

const actions = {
  async getCep({commit}, {cep, onSucesso,onErro}) {
    try{
      const list = (await pegaDados(url+'/get_cep', {cep: cep})).data
      onSucesso(list)
    } catch (error) {
      console.log('ERROR: ', error)
      onErro()
    }

  },

  async processarContato({commit}, {contato, acao, onSucesso,onErro}) {
    try {
      let res = await processar(url+'/enviar', contato, acao)
      onSucesso()
      EventBus.$emit('CONTATO_PROCESSO_CONCLUIDO', true)
    } catch (error) {
      console.log('ERROR: ', error)
      onErro()
      EventBus.$emit('CONTATO_PROCESSO_CONCLUIDO', false)
    }
  },
}


const mutations = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
